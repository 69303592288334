@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,400;0,700;0,800;0,900;1,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  font-family:  'Poppins', sans-serif;;
}
#root{
  background-image: url(/static/media/bg.19855d04.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}
svg{
  margin: 0 5px;
}
.App {
  text-align: center;
  width: 900px;
  max-width: 100%;
    margin: 0 auto;
}
.wallet-adapter-dropdown {
  position: fixed !important;
  left: 0;
  right: 0;
}
button.wallet-adapter-button.wallet-adapter-button-trigger {
  justify-content: center;
  text-align: right;
  position: absolute;
  right: 20px;
  top: 20px;
}
#table-title {
  font-size: 36px;
  color: #ae5fca;
  text-transform: capitalize;

  display: block;
  text-align: left;
}
#sub-title {
  text-align: left;
  color: gray;
  border-bottom: 1px solid gray;
  width: 180px;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
#love {
  color: #fff;
  width: 200px;
  bottom: 24px;
  margin: 100px auto;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  text-transform: capitalize;
  -webkit-filter: drop-shadow(2px 4px 6px black);
          filter: drop-shadow(2px 4px 6px black);
}
  #love  a{
      text-decoration: none;
  }
  #love  span#logo {
      display: block;
      background: linear-gradient(96.02deg, #0CDAC4 0.22%, #395DF0 31.45%, #FD49AD 71.02%, #FF845A 104.34%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      --WebkitTextFillColor: transparent;
  }
  #love  img{
      width: 40px;
  }


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #c95fc1;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  margin: 20px auto;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
